<template>
  <div>
    <b-row class="match-height">
      <b-col
        xl="4"
        md="4"
        sm="6"
        v-show="$Can('view_roles_and_permissions_btn')"
      >
        <settings-card
          icon="ShieldIcon"
          statistic="Roles & Permissions"
          link="userrole"
          statistic-title="User Roles"
          color="success"
        />
      </b-col>

      <b-col xl="4" md="4" sm="6" v-show="$Can('view_bank_holidays_btn')">
        <settings-card
          icon="BookOpenIcon"
          statistic="Bank Holidays"
          link="bank-holidays"
          statistic-title="Bank Holidays"
          color="success"
        />
      </b-col>

      <b-col xl="4" md="4" sm="6" v-show="$Can('view_tasks_btn')">
        <settings-card
          icon="ListIcon"
          statistic="Tasks"
          link="tasks"
          statistic-title="Tasks"
          color="primary"
        />
      </b-col>

      <b-col xl="4" md="4" sm="6" v-show="$Can('view_department_btn')">
        <settings-card
          icon="HomeIcon"
          statistic="Department"
          link="departments"
          statistic-title="Department"
          color="warning"
        />
      </b-col>

      <b-col xl="4" md="4" sm="6" v-show="$Can('view_site_btn')">
        <settings-card
          icon="LayersIcon"
          statistic="Site"
          link="sites"
          statistic-title="Site"
          color="warning"
        />
      </b-col>

      <b-col xl="4" md="4" sm="6" v-show="$Can('view_timeline_actions')">
        <settings-card
          icon="ActivityIcon"
          statistic="Actions Timeline"
          link="actions-timeline"
          statistic-title="Actions Timeline"
          color="danger"
        />
      </b-col>

      
      <b-col xl="4" md="4" sm="6" v-show="$Can('view_timeline_actions')">
        <settings-card
          icon="MailIcon"
          statistic="Email Test"
          link="emails-test"
          statistic-title="Email Test"
          color="primary"
        />
      </b-col>

    </b-row>
  </div>
</template>

<script>
import {
  BFormGroup,
  BTable,
  BRow,
  BCol,
  BFormSelect,
  BPagination,
} from "bootstrap-vue";

import useUserList from "./useSettings";

import store from "@/store";
import { avatarText } from "@core/utils/filter";
import { onUnmounted } from "@vue/composition-api";
import settingsStoreModule from "./settingsStoreModule";
import SettingsCard from "@core/components/statistics-cards/SettingsCard.vue";
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BTable,
    BFormSelect,
    BPagination,

    SettingsCard,
  },

  methods: {},

  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = "settings";
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME))
      store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME))
        store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });

    const { loading } = useUserList();

    return {
      loading,
      // Filter
    };
  },
  mounted() {},
};
</script>
